import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import Cushion from "../ui/Cushion"
import withTheme from "../ui/Theme/withTheme"
import Svg from "./Svg"

const ConcaveBottom = ({ children, fill: fillKey, uiTheme }) => {
  const [containerHeight, setContainerHeight] = useState(0)
  const containerRef = useRef()
  const fill = get(uiTheme, `colors.${fillKey}`, fillKey)

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight)
    }
  }, [containerRef])

  return (
    <Cushion top={containerHeight} style={{ position: "relative" }}>
      {children}
      <div
        ref={containerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          lineHeight: 0,
        }}
      >
        <Svg fill={fill} />
      </div>
    </Cushion>
  )
}

ConcaveBottom.defaultProps = {
  children: null,
  fill: "white",
  uiTheme: {},
}
ConcaveBottom.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.string,
  uiTheme: PropTypes.shape({}),
}
export default withTheme(ConcaveBottom)
