import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"

const NewsArticleCard = ({ image, link, snippet, title }) => {
  return (
    <Link to={link}>
      <Cushion component="div" id={title}>
        <Rectangle fill="white" radius="rounded">
          <Cushion all="small">
            {image && <img src={image} alt="" style={{ width: "100%" }} />}
            <Cushion top="small" bottom="xsmall">
              <Typography component="h3" preset="subheading" color="black">
                {title}
              </Typography>
            </Cushion>
            <Typography component="p" preset="body" color="darkGrey">
              {snippet}
            </Typography>
          </Cushion>
        </Rectangle>
      </Cushion>
    </Link>
  )
}

NewsArticleCard.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
export default NewsArticleCard
