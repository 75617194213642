import React from "react"
import PropTypes from "prop-types"

const Svg = ({ fill }) => {
  return (
    <svg width="100%" length="auto" viewBox="0 0 1440 110">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M1440,110 L1440,0 L0,0 L0,110 C346.666667,48.6666667 580.666667,18 702,18 C823.333333,18 1069.33333,48.6666667 1440,110 Z"
          id="concave-top"
          fill={fill}
        ></path>
      </g>
    </svg>
  )
}

Svg.propTypes = {
  fill: PropTypes.string,
}
export default Svg
