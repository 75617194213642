import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Flex from "../ui/Flex"
import Typography from "../ui/Typography"
import googleReviewsGreyImage from "../../images/google-reviews-grey.png"

const Review = ({ name, review, rating }) => {
  return (
    <Cushion all="medium">
      <Rectangle radius="rounded" fill="white" component="div">
        <Flex flexDirection="column" style={{ height: "100%" }}>
          <Typography
            preset="body"
            align="center"
            component="div"
            color="darkGrey"
            style={{ flexGrow: 1 }}
          >
            {review}
          </Typography>
          <Cushion top="small">
            <Flex alignItems="center" justifyContent="space-between">
              <img
                src={googleReviewsGreyImage}
                alt="google reviews logo"
                style={{ width: "100%", maxWidth: 110 }}
              />
              <Typography component="div" align="right" style={{ flexGrow: 1 }}>
                <Typography preset="subheading" color="medgrey" align="center">
                  {name}
                </Typography>
                <Cushion left="small">
                  <Typography preset="heading" color="brandBlue" align="center">
                    {rating}/5
                  </Typography>
                </Cushion>
              </Typography>
            </Flex>
          </Cushion>
        </Flex>
      </Rectangle>
    </Cushion>
  )
}

Review.propTypes = {
  name: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
}
export default Review
