import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"
import googleReviewsImage from "../../images/google-reviews.png"

const ReviewsBanner = ({ reviewsCount, reviewsAvgRating }) => {
  return (
    <Cushion horizontal="medium" vertical="small">
      <Rectangle component="div" fill="#001d31">
        <Flex alignItems="center" justifyContent="center">
          <Typography
            component="div"
            preset="subheading"
            color="white"
            align="center"
          >
            {`#BudgeThatSludge is our moto. ${reviewsCount} reviews. Average rating ${reviewsAvgRating}/5 `}
          </Typography>

          <a
            target="_blank"
            href="https://www.google.com/search?q=a-rated+power+flush&rlz=1C5CHFA_enGB938GB939&oq=a-rated+power+flush+&&ie=UTF-8#lrd=0x48796814206fa395:0xf37313b9c2931067,1,,,"
          >
            <img
              src={googleReviewsImage}
              alt="google reviews logo"
              style={{ width: "100%", maxWidth: 110 }}
            />
          </a>
        </Flex>
      </Rectangle>
    </Cushion>
  )
}

ReviewsBanner.propTypes = {
  reviewsCount: PropTypes.number.isRequired,
  reviewsAvgRating: PropTypes.number.isRequired,
}
export default ReviewsBanner
