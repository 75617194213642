import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-commonmark"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Button from "../components/ui/Button"
import Cushion from "../components/ui/Cushion"
import Flex from "../components/ui/Flex"
import Grid from "../components/ui/Grid"
import MaxWidth from "../components/ui/MaxWidth"
import Rectangle from "../components/ui/Rectangle"
import Space from "../components/ui/Space"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"
import ConcaveTop from "../components/ConcaveTop"
import NewsArticleCard from "../components/NewsArticleCard"
import ReviewsBanner from "../components/ReviewsBanner"
import Review from "../components/Review"

function getRandomWord(words) {
  const randomIndex = Math.floor(Math.random() * words.length)

  return words[randomIndex].reason
}

const Homepage = ({ data, location }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
    homepageJson,
    contactDetailsJson,
    allKnowledgeCentreJson = [],
    allAdditionalProductsJson = [],
  } = data

  const {
    introduction,
    whyARated,
    whatIsAPowerFlush,
    knowledgeCentre,
    googleReviewsCount,
    googleReviewsRating,
    reviews,
    additionalProducts,
  } = homepageJson
  const { reasons, content: introContent, benefits } = introduction[0]

  const {
    title: whatIsAPowerFlushTitle,
    image: whatIsAPowerflushImage,
    content: whatIsAPowerFlushContent,
  } = whatIsAPowerFlush[0]

  const {
    title: knowledgeCentreTitle,
    content: knowledgeCentreContent,
  } = knowledgeCentre[0]

  const {
    title: reviewsTitle,
    content: reviewsContent,
    reviewsSample,
  } = reviews[0]

  const {
    title: additionalProductsTitle,
    content: additionalProductsContent,
  } = additionalProducts[0]

  const articles = allKnowledgeCentreJson.edges.map(article => article.node)
  const additionalProductsItems = allAdditionalProductsJson.edges.map(
    product => product.node
  )

  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}
    >
      <SEO title={siteTitle} />
      <ReviewsBanner
        reviewsCount={googleReviewsCount}
        reviewsAvgRating={googleReviewsRating}
      />
      <ThermalBackground component="div">
        <ConcaveBottom>
          <Cushion
            component="div"
            horizontal="small"
            vertical="xxxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Typography component="div" preset="hero" color="white">
                {getRandomWord(reasons)}
              </Typography>
              <Cushion component="div" top="large">
                <Link to="/book/">
                  <Button theme="blue">Get a quote</Button>
                </Link>
              </Cushion>
              <Cushion top="medium" bottom="medium">
                <MaxWidth maxWidth={700} center>
                  <Typography
                    component="p"
                    preset="body"
                    color="white"
                    align="center"
                  >
                    {introContent}
                  </Typography>
                </MaxWidth>
              </Cushion>
              {benefits.map(({ benefit }) => (
                <Cushion bottom="xsmall" key={benefit}>
                  <Typography
                    component="div"
                    align="center"
                    preset="body"
                    color="white"
                  >
                    {benefit}
                  </Typography>
                </Cushion>
              ))}
              <Cushion component="div" top="medium">
                <Link to="/reasons-for-powerflush/">
                  <Button theme="grey">Find out more</Button>
                </Link>
              </Cushion>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
      <Cushion vertical="medium">
        <Rectangle fill="white" component="div">
          <MaxWidth maxWidth={1200} center>
            <Flex
              flexDirection="column"
              responsive={[
                {
                  minWidth: "tab",
                  props: {
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                },
              ]}
            >
              {whyARated.map(({ title, image, content }) => (
                <Cushion key={title} horizontal="medium" vertical="medium">
                  <Typography component="div" align="center">
                    <Space all="auto">
                      <Rectangle
                        radius="50%"
                        style={{
                          position: "relative",
                          width: "200px",
                          height: "200px",
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      />
                    </Space>
                    <Cushion top="small" bottom="xsmall">
                      <Typography
                        component="h3"
                        preset="subheading"
                        color="black"
                      >
                        {title}
                      </Typography>
                    </Cushion>
                    <Typography component="p" preset="body" color="darkGrey">
                      {content}
                    </Typography>
                  </Typography>
                </Cushion>
              ))}
            </Flex>
          </MaxWidth>
        </Rectangle>
      </Cushion>
      <ThermalBackground>
        <ConcaveTop>
          <ConcaveBottom fill="vLightGrey">
            <MaxWidth maxWidth={1200} center>
              <Cushion horizontal="medium" vertical="xxxxlarge">
                <Grid
                  columns="1fr"
                  rowGap="xxlarge"
                  responsive={[
                    {
                      minWidth: "tab",
                      props: {
                        columns: "repeat(2, minmax(0, 1fr))",
                        alignItems: "center",
                      },
                    },
                  ]}
                >
                  <Cushion component="div" right="medium">
                    <Cushion bottom="medium">
                      <Typography component="h2" preset="heading" color="white">
                        {whatIsAPowerFlushTitle}
                      </Typography>
                    </Cushion>
                    <Typography component="div" preset="body" color="white">
                      <ReactMarkdown source={whatIsAPowerFlushContent} />
                    </Typography>
                    <Cushion top="medium">
                      <Typography component="div" align="center">
                        <Link to="/explain-powerflush/">
                          <Button theme="blue">Find out more</Button>
                        </Link>
                      </Typography>
                    </Cushion>
                  </Cushion>
                  <MaxWidth component="div" maxWidth={400} center>
                    <Rectangle radius="rounded">
                      <img
                        src={whatIsAPowerflushImage}
                        alt="A man carrying out a PowerFlush"
                        style={{ width: "100%" }}
                      />
                    </Rectangle>
                  </MaxWidth>
                </Grid>
              </Cushion>
            </MaxWidth>
          </ConcaveBottom>
        </ConcaveTop>
      </ThermalBackground>
      <MaxWidth maxWidth={1200} center>
        <Cushion horizontal="medium" vertical="xxxxlarge">
          <Cushion bottom="medium">
            <Typography component="h2" preset="heading" color="black">
              {reviewsTitle}
            </Typography>
          </Cushion>
          <Typography component="div" preset="body" color="darkGrey">
            {reviewsContent}
          </Typography>
          <Cushion vertical="medium">
            <Grid
              columns="1fr"
              rowGap="medium"
              columnGap="medium"
              responsive={[{ minWidth: "tab", props: { columns: "1fr 1fr" } }]}
            >
              {reviewsSample.map(({ review, rating, name }) => (
                <Review review={review} rating={rating} name={name} />
              ))}
            </Grid>
          </Cushion>
          <Flex alignItems="center" justifyContent="center">
            <Cushion
              component="div"
              responsive={[{ minWidth: "palm", props: { right: "medium" } }]}
            >
              <a
                target="_blank"
                href="https://www.google.com/search?q=a-rated+power+flush&rlz=1C5CHFA_enGB938GB939&oq=a-rated+power+flush+&&ie=UTF-8#lrd=0x48796814206fa395:0xf37313b9c2931067,1,,,"
              >
                <Button theme="grey">See more reviews</Button>
              </a>
            </Cushion>
            <a target="_blank" href="https://g.page/r/CWcQk8K5E3PzEAg/review">
              <Button theme="blue">Write a review</Button>
            </a>
          </Flex>
        </Cushion>
      </MaxWidth>

      <MaxWidth maxWidth={1200} center>
        <Cushion horizontal="medium" vertical="xxxxlarge">
          <Cushion bottom="medium">
            <Typography component="h2" preset="heading" color="black">
              {additionalProductsTitle}
            </Typography>
          </Cushion>
          <Typography component="div" preset="body" color="darkGrey">
            {additionalProductsContent}
          </Typography>
          <Cushion top="medium">
            <Grid
              columns="1fr"
              columnGap="medium"
              rowGap="medium"
              responsive={[
                {
                  minWidth: 500,
                  props: { columns: "repeat(2, minmax(0, 1fr))" },
                },
                {
                  minWidth: 1000,
                  props: { columns: "repeat(4, minmax(0, 1fr))" },
                },
              ]}
            >
              {additionalProductsItems.map(
                ({ image, title, fields: { slug } }) => (
                  <NewsArticleCard link={slug} image={image} title={title} />
                )
              )}
            </Grid>
          </Cushion>
        </Cushion>
      </MaxWidth>

      <MaxWidth maxWidth={1200} center>
        <Cushion horizontal="medium" vertical="xxxxlarge">
          <Cushion bottom="medium">
            <Typography component="h2" preset="heading" color="black">
              {knowledgeCentreTitle}
            </Typography>
          </Cushion>
          <Typography component="div" preset="body" color="darkGrey">
            {knowledgeCentreContent}
          </Typography>
          <Cushion top="medium">
            <Grid
              columns="1fr"
              columnGap="medium"
              rowGap="medium"
              responsive={[
                {
                  minWidth: 500,
                  props: { columns: "repeat(2, minmax(0, 1fr))" },
                },
                {
                  minWidth: 1000,
                  props: { columns: "repeat(4, minmax(0, 1fr))" },
                },
              ]}
            >
              {articles.map(({ snippet, image, title, fields: { slug } }) => (
                <NewsArticleCard
                  link={slug}
                  image={image}
                  title={title}
                  snippet={snippet}
                />
              ))}
            </Grid>
          </Cushion>
        </Cushion>
      </MaxWidth>
    </Frame>
  )
}

Homepage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Homepage

export const pageQuery = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contactDetailsJson {
      email
      mobile
      landline
    }
    allAdditionalProductsJson(limit: 4) {
      edges {
        node {
          id
          title
          image
          body
          fields {
            slug
          }
        }
      }
    }
    allKnowledgeCentreJson(limit: 4) {
      edges {
        node {
          snippet
          date
          image
          id
          title
          fields {
            slug
          }
        }
      }
    }
    homepageJson {
      introduction {
        benefits {
          benefit
        }
        content
        reasons {
          reason
        }
      }
      knowledgeCentre {
        content
        title
      }
      whatIsAPowerFlush {
        content
        image
        title
      }
      whyARated {
        content
        image
        title
      }
      googleReviewsCount
      googleReviewsRating
      reviews {
        content
        title
        reviewsSample {
          rating
          name
          review
        }
      }
      additionalProducts {
        content
        title
      }
    }
  }
`
